<template>
  <div id="resources">
    <div class="h-banner">
      <div class="h-title" :style="{fontSize: $i18n.locale === 'zh-cn' ? '66px' : '72px', lineHeight: $i18n.locale === 'zh-cn' ? '70px' : '65px'}">
        {{$t('Resources.Banner')}}
      </div>
    </div>
    
    <div class="p1">
      <div class="list">
        <div class="item">
          <img src="../../assets/img/resources1.png" />
          <div class="content">
            <div class="title">{{$t('Resources.Row_1._1_1')}}</div>
            <!-- <a href="">Read the Whitepaper</a> -->
            <router-link class="read" target="_blank" to="/Roxe Chain White Paper.pdf">{{$t('Resources.Row_1._1_2')}}</router-link>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/img/resources2.png" />
          <div class="content">
            <div class="title">{{$t('Resources.Row_1._2_1')}}</div>
            <a href="https://github.com/RoxeNet/RoxeChain">{{$t('Resources.Row_1._2_2')}}</a>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/img/resources3.png" />
          <div class="content">
            <div class="title">{{$t('Resources.Row_1._3_1')}}</div> 
            <router-link class="read" target="_blank" to="/Daollar Protocol White Paper.pdf">{{$t('Resources.Row_1._3_2')}}</router-link>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/img/resources4.png" style="width: 84px; height: 84px;"/>
          <div class="content">
            <div class="title">{{$t('Resources.Row_1._4_1')}}</div>
            <span style="padding: 12px 20px;background: #9fa1a4; border: #9fa1a4; color: #fff">{{$t('Resources.Row_1._4_2')}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="p7">
      <div class="title">
        {{$t('Resources.Row_2._1')}}
      </div>
      <div class="link">
        <a href="https://twitter.com/RoxeChain" target="_blank"><img src="../../components/img/twitter.svg"/></a>
        <!-- <img src="../../components/img/facebook.svg"/> -->
        <a href="https://t.me/roxechainglobal" target="_blank"><img src="../../components/img/telegram.svg"/></a>
        <a href="https://www.linkedin.com/company/roxe-network" target="_blank"><img src="../../components/img/linkin.svg"/></a>
        <a href="https://medium.com/roxechain" target="_blank"><img src="../../components/img/m.svg"/></a>
        <a href="https://discord.gg/d3mDxd6th7" target="_blank"><img src="../../components/img/Union.svg"/></a>
      
      </div>
    </div>

     
    <!-- <roxe-footer></roxe-footer> -->
  </div>
</template>

<script>
// import RoxeFooter from "../../components/footer.vue";
export default {
  name: "resources",
  components: {
    // RoxeFooter
  },
  methods: {},
};
</script>
<style lang="less" scoped>
#resources {
  .h-banner {
    height: 387px;
    background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
    overflow: hidden;
    color: #ffffff;
    position: relative;
    .h-title {
      font-family: ParmaPetit;
      font-size: 80px;
      line-height: 100.3%;
      text-align: center;
      margin: 160px 0px 20px;
    }
    .h-title1 {
      font-family: Louis George Cafe;
      font-size: 26px;
      line-height: 20px;
      text-align: center;
    }
  }

  .p1 {
    .list {
      width: 1110px;
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
      margin-bottom: 100px;
      .item {
        display: flex;
        justify-content: flex-start;
        width: 540px;
        padding: 67px 0 67px 37px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        img {
          margin-right: 32px;
          width: 80px;
        }
        .title {
          font-family: Louis George Cafe;
          font-size: 24px;
          line-height: 28px;
          color: #085DA3;
          margin-bottom: 20px;
        }
        a {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #FFFFFF;
          padding: 13px 20px;
          background: #2F98EE;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  
  .p7 {
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085DA3;
      margin-top: 74px;   
      margin-bottom: 53px;     
    }
    .link {
      display: flex;
      justify-content: center;
      margin-bottom: 110px;
      a {
        margin-right: 30px;
         &:last-child {
          margin-right: 0px;
        }
        img {
          display: inline-block;
          width: 26px;
          &:hover {
            filter: contrast(0.5);
          }
        }
      }
    }
    
  }
}
@media screen and (min-width: 320px) and (max-width: 1210px) {
  #resources .p1 {
    padding-left: 50px;
    padding-right: 50px;
    .list {
      width: auto;
      margin: 0 auto;
      flex-wrap: wrap;
      margin-top: 50px;
      margin-bottom: 100px;
      display: block;
      .item {
        display: block;
        width: 100%;
        padding: 67px 0 67px 0px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        img {
          margin: 0 auto;
          display: block;
        }
        .title {
          text-align: center;
        }
        a,span {
          margin: 0 auto;
          display: block;
          width: fit-content;
        }
      }
    }
  }
  
}

</style>
